<template>
  <div class="mb-4">
    <div class="head">
      <div class="topdiv">
        <span>
          <span style="font-size: 15px"> 当前城市：</span>
          <el-cascader
            v-model="locationarr"
            :options="provincial"
            @change="handleChange"
            style="width: 185px"
          />
          <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/zhuce.html"
            style="margin-left: 10px"
            >免费注册</a
          >
        </span>
        <span>
          <a class="toptext" target="_blank" href="http://www.56360.cn/"
            >56360首页</a
          >
          <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/56360app.html"
            >56360APP</a
          >
          <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/yunpingtai.html"
            >56360云平台</a
          >
          <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/wuliuyun.html"
            >56360物流云</a
          >

          <!-- <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/56360app.html"
            >微信小程序</a
          > -->
          <span class="toptext" @click="xcxfn" style="cursor: pointer"
            >微信小程序</span
          >
          <span class="toptext" style="cursor: pointer" @click="gzhfn"
            >微信公众号</span
          >
          <!-- <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/56360app.html"
            >微信公众号</a
          > -->
          <a
            class="toptext"
            target="_blank"
            href="http://www.56360.cn/chaxun/genzong.html"
            style="margin-right: 0"
            >货物跟踪</a
          >
        </span>
      </div>
      <div class="divimg">
        <img src="@/assets/logo2.jpg" class="logo" />
        <div class="imgtxt">
          <span>供应链</span>
          <br />
          <span>联接你我他</span>
        </div>
      </div>
      <div class="divxinwen">
        <div class="xinwentxt">
          活动：<a href="http://www.56360.cn/huodong/2.html" target="_blank"
            >56360产品全面升级</a
          >
          <br />
          新闻：<a
            href="http://www.56360.cn/56360/xinwen/1.html"
            target="_blank"
            >56360与人民保险达成战略合作</a
          >
        </div>
      </div>
      <div class="headbody">
        <el-menu
          class="horizontal-menu"
          :default-active="activeIndex"
          mode="horizontal"
          @select="handleSelect"
          active-text-color="#00cd00"
          text-color="#303133"
          background-color="#ffffff"
        >
          <el-menu-item
            class="el-menu-itemCLS"
            index="/redirec/SpecialLine"
            style="font-size: 28px; margin-right: 40px"
            >找专线</el-menu-item
          >
          <el-menu-item
            class="el-menu-itemCLS"
            index="/redirec/DirectLine"
            style="font-size: 28px"
            >找直销</el-menu-item
          >
        </el-menu>
      </div>
      <div class="container">
        <div class="search" v-if="activeIndex == '/redirec/DirectLine'">
          <div class="mt-4">
            <el-input
              v-model="input3"
              placeholder="请输入商品名称"
              class="input-with-select"
              :prefix-icon="Search"
            >
              <template #append>
                <el-button type="primary" class="inputbut">搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
        <div class="search" v-else>
          <div class="mt-4" @click="SpecialLinefn">
            <el-input
              readonly
              placeholder="请选择起始地和目的地"
              class="input-with-select"
              :prefix-icon="Search"
            >
              <template #append>
                <el-button type="primary" class="inputbut">搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #f2f2f2">
      <div class="body">
        <RouterView :key="route.fullPath" />
      </div>
    </div>
    <div class="Footer">
      <el-row type="flex">
        <el-col :span="2" class="link-container leftcon">
          <h4>关于56360</h4>
          <a target="_blank" href="http://www.56360.cn/56360.html">56360简介</a>
          <a target="_blank" href="http://www.56360.cn/56360/wenhua.html"
            >公司文化</a
          >
          <a target="_blank" href="http://www.56360.cn/56360/xinwen.html"
            >公司新闻</a
          >
          <a target="_blank" href="http://www.56360.cn/huodong.html"
            >市场活动</a
          >
          <a target="_blank" href="http://www.56360.cn/56360/zhaoping.html"
            >人才招聘</a
          >
          <a target="_blank" href="http://www.56360.cn/56360/women.html"
            >联系我们</a
          >
        </el-col>
        <el-col :span="2" class="link-container">
          <h4>供应链产品</h4>
          <a target="_blank" href="http://www.56360.cn/56360app.html"
            >56360APP</a
          >
          <a target="_blank" href="http://www.56360.cn/yunpingtai.html"
            >56360云平台</a
          >
          <a target="_blank" href="http://www.56360.cn/wuliuyun.html"
            >56360物流云</a
          >
        </el-col>
        <el-col :span="2" class="link-container">
          <h4>解决方案</h4>
          <a target="_blank" href="http://www.56360.cn/jiejuefangan/app.html"
            >APP方案</a
          >
          <a target="_blank" href="http://www.56360.cn/jiejuefangan/ypt.html"
            >云平台方案</a
          >
          <a target="_blank" href="http://www.56360.cn/jiejuefangan/wly.html"
            >物流云方案</a
          >
        </el-col>
        <el-col :span="2" class="link-container">
          <h4>人工智能</h4>
          <a href="http://www.56360.cn/ai/yunpingtai.html" target="_blank"
            >AI云平台</a
          >
          <a href="http://www.56360.cn/ai/wuliuyun.html" target="_blank"
            >AI物流云</a
          >
        </el-col>
        <el-col :span="2" class="link-container">
          <h4>自助查询</h4>
          <a href="http://www.56360.cn/chaxun/genzong.html" target="_blank"
            >货物跟踪</a
          >
          <a href="http://www.56360.cn/chaxun/chengpei.html" target="_blank"
            >城配查询</a
          >
          <!-- <a
              href="http://www.56360.cn/chaxun/zhuanxian.html"
              onClick="f('ps.html')"
              >专线查询</a
            > -->
          <a href="http://www.56360.cn/chaxun/kuaidi.html" target="_blank"
            >快递查询</a
          >
        </el-col>
        <el-col :span="2" class="link-container">
          <h4>合作伙伴</h4>
          <a target="_blank" href="http://www.56360.cn/api/huozhuapi.html"
            >API合作</a
          >
          <a target="_blank" href="http://www.56360.cn/api/tuiguanghezuo.html"
            >推广合作</a
          >
          <a target="_blank" href="http://www.56360.cn/api/hehuoren.html"
            >合伙人</a
          >
        </el-col>

        <el-col :span="2" class="link-container botcon">
          <h4>客服中心</h4>
          <a href="http://www.56360.cn/kefu/zhuce.html" target="_blank"
            >新手入门</a
          >
          <a href="http://www.56360.cn/kefu/weijin.html" target="_blank"
            >业务指南</a
          >
        </el-col>

        <el-col :span="6" class="img-con">
          <h4>客服热线</h4>
          <div class="hotline-number" style="color: #0f0f0f; font-size: 28px">
            0537-2222287
          </div>

          <div class="social-media-icons">
            <div class="icon-container">
              <img src="@/assets/images/xcx.png" alt="" class="icon-image" />
              <span class="icon-description">官方微信小程序</span>
            </div>
            <div class="icon-container">
              <img src="@/assets/images/56360.jpg" alt="" class="icon-image" />
              <span class="icon-description">官方微信公众号</span>
            </div>
            <div class="icon-container">
              <img
                src="@/assets/images/erweima32.png"
                alt=""
                class="icon-image"
              />
              <span class="icon-description">56360APP</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div
        class="buttext"
        style="
          float: left;
          text-align: center;
          width: 100%;
          font-size: 13px;
          background-color: #00cd00;
          color: #666666;
        "
      >
        Copyright © 2024 56360.com 56360.cn All rights reserved.
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a
          target="_blank"
          href="http://beian.miit.gov.cn/"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
            color: #666666;
          "
          >京ICP备10208812号-1</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a
          target="_blank"
          href="http://beian.miit.gov.cn/"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
            color: #666666;
          "
          >鲁ICP备14010521号-12</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37081102000294"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
            color: #666666;
          "
          >鲁公网安备 37081102000294号</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
          href="http://www.kuheng.com"
          target="_blank"
          style="text-decoration: none; color: #666666"
          >技术开发</a
        >
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogFormVisible"
    title="选择起始地和目的地"
    width="500"
    @close="closefn"
    :lock-scroll="false"
    :append-to-body="true"
  >
    <el-form :model="form">
      <el-form-item label="起始地">
        <el-cascader
          v-model="form.startList"
          :options="provincial"
          class="custom-class"
          style="width: 100%"
          placeholder="请选择或输入起始地"
          filterable
        />
      </el-form-item>
      <el-form-item label="目的地">
        <el-cascader
          v-model="form.goalList"
          :props="props1"
          :options="provincialList"
          class="custom-class"
          style="width: 100%"
          placeholder="请选择或输入目的地"
          filterable
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="success" @click="dialogsearchfn"> 搜索 </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    v-model="xcxType"
    title="56360微信小程序"
    width="300"
    align-center
    :lock-scroll="false"
    :append-to-body="true"
  >
    <div class="dialog-img-centered">
      <img src="@/assets/images/xcx.png" style="width: 200px; height: 200px" />
    </div>
  </el-dialog>
  <el-dialog
    v-model="gzhType"
    title="56360微信公众号"
    width="300"
    align-center
    :lock-scroll="false"
    :append-to-body="true"
  >
    <div class="dialog-img-centered">
      <img
        src="@/assets/images/56360.jpg"
        style="width: 200px; height: 200px"
      />
    </div>
  </el-dialog>
</template>
<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { pcTextArr } from "element-china-area-data";
import { useRouter, useRoute } from "vue-router";

import { pinyin } from "pinyin-pro";
import { provincial, provincialList } from "@/utils/provincial.js";
import { Search, ArrowDown } from "@element-plus/icons-vue";
import useUserStore from "@/stores/user"; //引入仓库
const user = useUserStore();
const router = useRouter();
const route = useRoute();
const props1 = {
  checkStrictly: true,
};
const form = ref({
  startList: ["山东省", "济宁市"],
  goalList: [],
});
let activeIndex = ref("/redirec/SpecialLine");
user.specList();
let dialogFormVisible = ref(false);
// router.push("/redirec/SpecialLine");
const handleSelect = (key, keyPath) => {
  console.log(key, keyPath);
  activeIndex.value = key;
  router.push(key);
};

const locationarr = ref(["山东省", "济宁市"]);
const handleChange = (value) => {
  console.log(value);
  user.locationarrfn(value);
  user.specList();
  form.value.startList = value;
};

navigator.geolocation.getCurrentPosition(
  // 成功回调
  function (position) {
    // console.log("纬度:", position.coords.latitude);
    let lat = position.coords.latitude.toFixed(3);
    // console.log("经度:", position.coords.longitude);
    let lng = position.coords.longitude.toFixed(3);
    // 处理其他坐标信息，如高度、精度等
    const BMapGL = window.BMapGL;
    // 创建地理编码实例
    var myGeo = new BMapGL.Geocoder();
    // 根据坐标得到地址描述
    myGeo.getLocation(new BMapGL.Point(lng, lat), function (result) {
      if (result) {
        // alert(result.address);
        console.log(result);
        locationarr.value = [
          result.addressComponents.province,
          result.addressComponents.city,
        ];
        user.locationarrfn(locationarr.value);
        user.specList();
        form.value.startList = locationarr.value;
      }
    });
  },
  // 错误回调
  function (error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("用户拒绝提供位置");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("位置信息不可用");
        break;
      case error.TIMEOUT:
        console.log("请求超时");
        break;
      case error.UNKNOWN_ERROR:
        console.log("未知错误");
        break;
    }
  },
  // 可选参数，设置定位选项
  {
    enableHighAccuracy: true, // 是否启用高精度定位，默认false
    timeout: 5000, // 请求超时时间，单位毫秒，默认无限
    maximumAge: 0, // 最大缓存年龄，单位毫秒，0表示不使用缓存
  }
);
function SpecialLinefn() {
  dialogFormVisible.value = true;
  form.value.startList = locationarr.value;
}
function closefn() {
  form.value.goalList = [];
}
let obj = {
  enStartProCity: "",
  enStartTown: "",
  enEndProvince: "",
  enEndCity: "",
  enEndTown: "",
  deleted: 0,
  pageNum: 1,
  pageSize: 10,
};
function dialogsearchfn() {
  obj.enStartProCity = pinyin(form.value.startList.join(""), {
    toneType: "none",
    separator: "",
  });
  obj.enEndProvince = pinyin(form.value.goalList[0], {
    toneType: "none",
    separator: "",
  });
  obj.enEndCity = pinyin(form.value.goalList[1], {
    toneType: "none",
    separator: "",
  });
  obj.enEndTown = pinyin(form.value.goalList[2], {
    toneType: "none",
    separator: "",
  });

  user.listfn(obj).then(() => {
    activeIndex.value = "/redirec/SearchLine";
    dialogFormVisible.value = false;
    let sstxt = `${form.value.startList.join("")}到${
      form.value.goalList.length ? form.value.goalList.join("") : "全国"
    }物流专线`;
    console.log(sstxt);
    user.sstxtfn(sstxt);
    obj.sstxt = sstxt;
    router.push({
      path: "/redirec/SearchLine",
      query: obj,
    });
  });
}
let xcxType = ref(false);

function xcxfn() {
  xcxType.value = true;
}
let gzhType = ref(false);
function gzhfn() {
  gzhType.value = true;
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

// :deep(.el-cascader .el-input.is-focus .el-input__wrapper) {
//   box-shadow: 0 0 0 1px #00cd00 !important;
//   // border: none;
// }

.horizontal-menu {
  display: inline-block;
  width: 400px;
}

.body {
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中 */
  overflow: auto; /* 清除浮动 */
  padding-top: 20px;
  background-color: #f2f2f2;
  :deep(.el-cascader .el-input__wrapper) {
    box-shadow: none !important;
  }

  /* 去除选中时蓝色边框（下面两个都要加上） */
  :deep(.el-input .el-input__wrapper.is-focus) {
    box-shadow: none !important;
  }

  :deep(.el-select .el-input.is-focus .el-input__wrapper) {
    border-color: #dcdfe6 !important;
    box-shadow: none !important;
  }
}
.search {
  float: left;
  margin-left: 10px; /* 添加一些间距 */
}
.topdiv {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: space-between; /* 添加此行 */
  padding-left: 70px;
  padding-right: 70px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  /* 去除边框 */
  :deep(.el-cascader .el-input__wrapper) {
    box-shadow: none !important;
  }

  /* 去除选中时蓝色边框（下面两个都要加上） */
  :deep(.el-input .el-input__wrapper.is-focus) {
    box-shadow: none !important;
  }

  :deep(.el-select .el-input.is-focus .el-input__wrapper) {
    border-color: #dcdfe6 !important;
    box-shadow: none !important;
  }
}
.headbody {
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  padding-top: 20px;
  justify-content: center; /* 垂直居中 */
  text-align: center; /* 如果有文本，也可以帮助文本居中 */
  padding-left: 150px;
  padding-right: 150px;
  margin-top: 20px;
  // margin-bottom: 20px;
}

.logo {
  width: 10vw;
  height: 70px;
  float: left;
  // margin-top: 20px;
  vertical-align: middle;
}
.input {
  width: 70vw;
}
.toptext {
  flex: 1;
  margin-right: 20px;
  color: #2c3e50;
  text-decoration: none;
  font-size: 15px;
  /* font-weight: 700; */
  text-align: center;
  line-height: 16px;
}
.inputbut {
  background-color: #00cd00 !important;
  color: #fff !important;
  height: 45px;
  width: 100px;
}
.input-with-select {
  width: 70vw;
  height: 45px;
  font-size: 17px;
  border: #00cd00 1px solid !important;
}
.custom-class {
  color: #00cd00 !important;
}
.divimg {
  position: absolute; /* 这使得.container成为.absolute元素的参照物 */
  left: 10%;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  margin-top: 20px;
  // width: 100%; /* 根据需要设定容器尺寸 */
  // height: 400px; /* 示例高度 */
}
.divxinwen {
  position: absolute; /* 这使得.container成为.absolute元素的参照物 */
  right: 10%;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  margin-top: 20px;
  // width: 100%; /* 根据需要设定容器尺寸 */
  // height: 400px; /* 示例高度 */
}
.imgtxt {
  margin-top: 10px;
  text-align: left; /* 文字右对齐 */
}
.xinwentxt {
  margin-top: 30px;
  text-align: left; /* 文字右对齐 */
  a {
    color: #2c3e50 !important;
    text-decoration: none;
  }
  a:hover {
    color: #fcb814 !important;
  }
}
.el-menu-itemCLS:hover {
  // color: #6a00d3 !important;
  background-color: #fff !important;
  color: #00cd00 !important;
}
.el-menu-itemCLS {
  background-color: #fff !important;
}
.Footer {
  // width: 99%;
  padding: 0;
  margin: 0;
  height: 260px;
  background-color: #00cd00;
  padding-left: 30px;
}
.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  margin-right: 30px;
  h4 {
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
    color: #454545;
    font-size: 15px;
    margin-bottom: 7px;
  }
  a:hover {
    color: #fcb814;
  }
}
.leftcon {
  margin-left: 10px;
}
.botcon {
  padding-right: 40px;
}

.img-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin-bottom: 10px;
  }
}

.hotline-number {
  /* ... */
}

.social-media-icons {
  display: flex;
  flex-wrap: nowrap; /* 防止换行 */
  justify-content: center; /* 水平居中 */
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap; /* 确保文本不换行 */
}

.icon-container:last-child {
  margin-right: 0;
}

.icon-image {
  width: 100px;
  margin-bottom: -8px;
}

.icon-description {
  margin-top: 11px;
  font-size: 14px;
  color: #0f0f0f;
}
.buttext {
  a:hover {
    color: #fcb814 !important;
  }
}
.buttonbox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.dialog-img-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
